import React, {useState, useEffect} from 'react';
import './App.scss';
import {Container, Header, Loader} from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/auth";
import SignInPage from "./pages/SignIn";
import Navigation from "./components/Navigation";

function App() {

    // Null: loading, false: not signed in
    const [user, setUser] = useState(null);
    const [appLoaded, setAppLoaded] = useState(false);
    const [appLoading, setAppLoading] = useState(false);

    useEffect(() => {
        if (user) return;
        firebase.app("ledger").auth().onAuthStateChanged(function (user) {
            if (user) {
                setUser(user);
                setAppLoading(true);

                user.getIdToken().then(ledgerToken => {
                    return fetch("https://ledger.livemegawatt.com/get-app/lake-turkana",
                        {
                            headers: {
                                "Authorization": "Bearer " + ledgerToken,
                                'Content-Type': 'application/json',
                            },
                            redirect: 'follow',
                        });
                }).then(response => {
                    return response.json();
                }).then(result => {
                    if (!result) {
                        setAppLoading(false);
                        throw new Error("Given app is not associated with given ledger account.");
                    }
                    const {jwt, firebaseOptions} = result;
                    firebase.initializeApp(firebaseOptions);
                    return firebase.auth().signInWithCustomToken(jwt);
                }).then(user => {
                    setAppLoaded(!!user);
                    setAppLoading(false);
                })

            } else {
                setUser(false);
                setAppLoaded(false);
                setAppLoading(false);
            }
        })
    });

    return <>
        <Container className="App">

            {(user === null || appLoading) ? <div style={{marginTop: '20%'}}>
                <Loader active inline='centered'/>
            </div> : !appLoaded ? <SignInPage/> :
                <Navigation/>
            }

        </Container>
    </>;
}

export default App;
