import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/database";
import {Button, Dropdown, Form, Grid, Icon, Segment} from "semantic-ui-react";
import update from "immutability-helper";
import axios from 'axios';

function TextEditComponent({part, onChange}) {
    const handleChange = e =>
        onChange(update(part, {
            value: {$set: e.target.value}
        }))
    return <Form.Field>
        <textarea value={part.value} onChange={handleChange}/>
    </Form.Field>;
}

function TextLineEditComponent({part, onChange}) {
    const handleChange = e =>
        onChange(update(part, {
            value: {$set: e.target.value}
        }))
    return <Form.Field>
        <input type="text" value={part.value} onChange={handleChange}/>
    </Form.Field>;
}

function LinkEditComponent({part, onChange}) {
    const handleChange = e =>
        onChange(update(part, {
            [e.target.name]: {$set: e.target.value}
        }))
    return <>
        <Form.Field>
            <input type="text" name="value" value={part.value} onChange={handleChange}/>
        </Form.Field>
        <Form.Field>
            <input type="text" name="url" value={part.url} onChange={handleChange}/>
        </Form.Field>
    </>;
}

function ImageEditComponent({part, onChange}) {
    const handleChange = async event => {

        onChange(update(part, {
            url: {$set: URL.createObjectURL(event.target.files[0])},
            file: {$set: event.target.files[0]},
            uploaded: {$set: false},
        }));
    };

    return <>
        <Form.Field>
            {part.url && part.url !== "" ? <div>
                <img src={part.url} style={{height: '100px', opacity: (part.uploaded === false ? 0.6: 1)}}/>
            </div> :
            <input type="file" name="url" onChange={handleChange}/>}
        </Form.Field>
    </>;
}

function EditComponent(props) {

    const {part} = props;

    const editComponents = {
        text: TextEditComponent,
        heading: TextLineEditComponent,
        link: LinkEditComponent,
        image: ImageEditComponent,
        movie: ImageEditComponent,
    };
    return editComponents[part.type] ? editComponents[part.type](props) : <></>;

}

function ContentEditor(options) {

    let {path, item} = options;
    // let _item = path.item;

    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);
    // const [item, setItem] = useState(_item);

    // useEffect(() => {
    //
    //     if (!item && !_item && path) {
    //         console.log("getting data");
    //         firebase.database().ref(path).get().then(function (snapshot) {
    //             if (snapshot.exists()) {
    //                 setItem(snapshot.val());
    //             }
    //         });
    //     } else {
    //         setItem(item);
    //     }
    //
    // }, [item, path, _item]);

    useEffect(() => {
        item && setContent(item.content || item);
    }, [item]);

    const updatePart = (i, part) => {
        setContent(update(content, {
            [i]: {$set: part}
        }))
    }

    const removePart = (e, i) => {
        if (!window.confirm("Are you sure you want to delete this section?")) return;
        e.preventDefault();
        const tempContent = [...content];
        tempContent.splice(i, 1);
        setContent(tempContent);
        return false;
    }
    const movePart = (e, i, direction) => {
        e.preventDefault();
        const to = i + (direction === "up" ? -1 : 1);
        const tempContent = [...content];
        tempContent.splice(to, 0, tempContent.splice(i, 1)[0])
        setContent(tempContent);
        return false;
    }
    const addPart = type => {
        let part = {
            text: {
                value: "",
            },
            heading: {
                value: "",
            },
            link: {
                value: "",
                url: "",
            },
        }[type] || {};
        part.type = type;
        setContent(update(content, {
            $push: [part]
        }))
    }

    const ucfirst = title => (title && (title[0].toUpperCase() + title.substring(1))) || "";

    const save = async (e) => {
        e.preventDefault();
        setLoading(true);

        //Upload files
        let contentToSave = [];

        for (let part of content) {

            if (part.type === "image" && part.uploaded === false) {

                let token = await firebase.auth().currentUser.getIdToken(true);
                const data = new FormData();
                data.append('file', part.file);
                let res = await axios.post("https://wpf-back-end.livemegawatt.com/file-upload", data, {
                    headers: {
                        "x-file-access": "public",
                        "x-auth-token": token,
                    }
                });
                if (res.status === 200 && res.data && res.data.url) {
                    part = {
                        type: "image",
                        url: res.data.url,
                        uploaded: true,
                        "image-height": res.data.height,
                        "image-width": res.data.width,
                    };
                } else {
                    alert("Something went wrong...");
                    continue;
                }
            }

            contentToSave.push(part);

        }


        const data = item && item.content ? {content: contentToSave} : contentToSave;
        firebase.database().ref(path).set(data).then(() => {
            window.document.location.reload();
        });
        return false;
    }

    const cancel = (e) => {
        e.preventDefault();
        window.location.reload();
        return false;
    }

    return <Form onSubmit={save} loading={loading}>

        {content.map((part, i) => <Segment vertical key={"part" + i}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={13}>
                        <EditComponent part={part} onChange={newPart => updatePart(i, newPart)}/>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {ucfirst(part.type)}<br/><br/>
                        <Button circular icon="remove" size="tiny"
                                onClick={(e) => removePart(e, i)}/>
                        <Button circular icon="angle up" size="tiny"
                                disabled={i === 0}
                                onClick={(e) => movePart(e, i, "up")}/>
                        <Button circular icon="angle down" size="tiny"
                                disabled={i >= content.length - 1}
                                onClick={(e) => movePart(e, i, "down")}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>)}

        <br/>

        <Dropdown text='Add section' button>
            <Dropdown.Menu>
                <Dropdown.Item text='Heading' onClick={() => addPart("heading")}/>
                <Dropdown.Item text='Text' onClick={() => addPart("text")}/>
                <Dropdown.Item text='Link' onClick={() => addPart("link")}/>
                <Dropdown.Item text='Image' onClick={() => addPart("image")}/>
                <Dropdown.Item text='Video' onClick={() => addPart("movie")}/>
            </Dropdown.Menu>
        </Dropdown>

        <Button type='submit' primary floated="right">Save</Button>
        <Button floated="right" onClick={cancel}>Cancel</Button>

    </Form>;
}

export default ContentEditor;
