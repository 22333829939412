import React from 'react';
import {Container, Header} from "semantic-ui-react";
import "firebase/auth";
import HeaderPhotos from "./HeaderPhotos";
import {ConfigContext} from "../../index";

function SettingsPage() {

    return (
        <>


            <ConfigContext.Consumer>
                {value => value.settings.headerPhotos &&
                    <HeaderPhotos/>
                }
            </ConfigContext.Consumer>

        </>
    );
}

export default SettingsPage;
